import React, { useEffect, useState } from "react";
import Newsletter from "../components/Newsletter";
import Section from "../components/Section";
import Modal from "react-modal";
import { useLocation } from "react-router-dom";
import Nav from "../components/Nav";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

Modal.setAppElement("#root");

const Personal = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const showModal = queryParams.get("fromGetStarted") === "true";

  const [showModalState, setShowModalState] = useState(showModal);

  const closeModal = () => {
    setShowModalState(false);
  };

  useEffect(() => {
    setShowModalState(showModal);
  }, [showModal]);

  return (
    <>
    <Nav />
      {showModalState && (
        <Modal
          isOpen={showModalState}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="payment information Modal"
        >
          <div className="text-center">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              Hey there!{" "}
            </h2>
            <p className="text-gray-600 mb-6">
              Thank you for contacting us. A member of our team will reach out
              to you shortly.
            </p>
          </div>
          <div className="text-center">
            <button
              type="button"
              onClick={closeModal}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Close
            </button>
          </div>
        </Modal>
      )}

      <div className="bg-[#F5F5F5]">
        <Section />
        <Newsletter />
      </div>
    </>
  );
};

export default Personal;
