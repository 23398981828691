import React from 'react'

const NoMatch  = () => {
  return (
    <div>
        <h1 className='text-3xl font-bold'>There's nothing Here!</h1>
    </div>
  )
}

export default NoMatch 