import React from "react";
import AccessChannel from "../components/AccessChannel";
import BusinessHero from "../components/BusinessHero";
import Faq from "../components/Faq";
import Reasons from "../components/Reasons";
import Testimonial from "../components/Testimonial";
import Newsletter from "../components/Newsletter";
import Nav from "../components/Nav";

const Business = () => {
  return (
    <div className="bg-[#F5F5F5]">
      <Nav />

      <BusinessHero />
      <Testimonial />
      <Reasons />
      <AccessChannel />
      <Faq />
      <Newsletter />
    </div>
  );
};

export default Business;
