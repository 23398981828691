import { BrowserRouter, Routes, Route } from "react-router-dom";

import Business from "../pages/Business";
import Home from "../pages/Home";
import Personal from "../pages/Personal";

import NoMatch from "../components/NoMatch ";
import Energy from "../pages/electricity/Energy";
import Airtime from "../pages/airtime/Airtime";
import Data from "../pages/data/Data";
import Cable from "../pages/TVsub/Cable";
import Bet from "../pages/Bet/Bet";
import Education from "../pages/Education/Education";
import PaymentVerification from "../pages/PaymentVerification";
import GetStarted from "../pages/GetStarted";

const AppRoutes = () => {
  return (
    <BrowserRouter>
     <Routes>
      <Route exact path="/" element={<Home />} />
      <Route index element={<Home />} />
      <Route path="*" element={<NoMatch />} />
      <Route path="/personal" element={<Personal />} />
      <Route path="/business" element={<Business />} />
      <Route path="/airtime" element={<Airtime />} />
      <Route path="/energy" element={<Energy />} />
      <Route path="/data" element={<Data />} />
      <Route path="/cable" element={<Cable />} />
      <Route path="/bet" element={<Bet />} />
      <Route path="/education" element={<Education />} />
      <Route path="/payment/verify" element={<PaymentVerification />} />
      <Route path="/getstarted" element={<GetStarted />} />
    </Routes>
    </BrowserRouter>
   
  );
};

export default AppRoutes;
