import Aos from "aos";
import React from "react";
import StoreBtn from "./StoreBtn";
import { useState } from "react";
import axios from "axios";

const Newsletter = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    axios.post(email);
    if (email.length > 0) {
      setSubmitted(true);
      setEmail("");
    }
  };

  const closeCard = (e) => {
    setSubmitted(false);
  };
  Aos.init();
  return (
    <>
      <div>
        {submitted ? (
          <div className="fixed inset-0 flex flex-col justify-center items-center bg-gray-700">
            <div className="max-w-md p-6 bg-white rounded-lg shadow-xl">
              <div className="text-center">
                <h2 className="text-2xl font-bold text-gray-800 mb-4">
                  Thank You for Subscribing!
                </h2>
                <p className="text-gray-600 mb-6">
                  We appreciate your support and look forward to sharing our
                  latest updates with you.
                </p>
              </div>
              <div className="text-center">
                <button
                  type="button"
                  onClick={closeCard}
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="800"
              className="mt-2 mx-2 w-full text-[#333333] px-8"
            >
              <div className="max-w-[1240px] mx-auto grid lg:grid-cols-3">
                <div className="mb-4">
                  <p className="mb-2">Coming soon</p>
                  <StoreBtn className="h-6" />
                </div>

                <div className=" lg:col-span-2">
                  <p className="text-[#909292] mb-2">Subscribe to get new updates</p>
                  <div className="flex flex-col sm:flex-row items-center justify-between w-full">
                    <input
                      type="email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="bg-gray-200 p-3 flex w-full md:w-[80%] rounded-md text-black"
                      placeholder="Your Email Address..."
                      style={{ fontStyle: "italic" }}
                    />
                    <button
                      type="submit"
                      className="bg-green-700 hover:bg-green-500  text-white w-[200px] rounded-md font-medium my-6 ml-4 px-6 py-3 "
                    >
                      SUBSCRIBE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </>
  );
};
export default Newsletter;
