import React from "react";

const SearchInput = () => {
  return (
    <div className="relative rounded-md shadow-sm">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <svg
          className="h-5 w-5 bg-[#F5F5F5] text-gray-400"
          viewBox="0 0 20 20"
          fill="none"
          stroke="currentColor"
        >
          <path d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" />
        </svg>
      </div>
      <input
        className="form-input flex text-black py-3 w-full leading-5 rounded-sm border border-gray-400 transition duration-150 ease-in-out"
        placeholder=""
        disabled
      />
    </div>
  );
};

export default SearchInput;
