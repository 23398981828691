import React from "react";
import web from "../assets/web.png";
import mobile from "../assets/mobile.png";
import desktop from "../assets/desktop.png";
import mpos from "../assets/mpos.png";

const AccessChannel = () => {
  return (
    <div className="w-full text-[#333333] bg-[#E9FFF6] px-4 py-16 mt-6">
      <h1 className="max-w-[1140px] mx-auto text-center md:text-4xl sm:text-3xl text-2xl font-bold mb-4">
        Access Channels{" "}
      </h1>
      <div className="max-w-[1140px] mx-auto grid md:grid-cols-4">
        <div className="mr-8 flex flex-col justify-center">
          <div className="my-4 text-center">
            <div className="flex justify-center items-center my-2">
              <img src={web} alt="web" className="" />
            </div>
            <h1 className="mb-4 md:text-2xl text-xl font-bold">Web </h1>
            <p className="pt-2">
              Login to your account and gain access to a robust dashboard add
              users to your account, download reports customize settings and
              much more
            </p>
          </div>
        </div>
        <div
          data-aos="fade-right"
          data-aos-delay="300"
          data-aos-duration="500"
          className="flex flex-col justify-center mx-2"
        >
          <div className="my-4 text-center">
            <div className="flex justify-center items-center my-2">
              <img src={mobile} alt="mobile-app" />
            </div>
            <h1 className="mb-4 md:text-2xl text-xl font-bold">Mobile App</h1>
            <p className="pt-2">
              Log into your BillerHQ account via the mobile application to see
              all your transaction information in one place.
            </p>
          </div>
        </div>
        <div
          data-aos="fade-right"
          data-aos-delay="500"
          data-aos-duration="500"
          className="flex flex-col justify-center mx-2"
        >
          <div className="my-4 text-center">
            <div className="flex justify-center items-center my-2">
              <img src={desktop} alt="desktop-app" />
            </div>
            <h1 className="mb-4 md:text-2xl text-xl font-bold">Desktop App</h1>
            <p className="pt-2">
              Sign into BillerHQ from a desktop computer to access users and
              user operation at all your point of sale.
            </p>
          </div>
        </div>
        <div
          data-aos="fade-right"
          data-aos-delay="700"
          data-aos-duration="500"
          className="flex flex-col justify-center mx-2"
        >
          <div className="my-4 text-center">
            <div className="flex justify-center items-center">
              <img src={mpos} alt="mpos" />
            </div>
            <h1 className="mb-4 md:text-2xl text-xl font-bold">MPOS</h1>
            <p className="pt-2">
              Activate BillerHQ desktop at all your point of sale and an
              unlimited number of users can perform operations simultaneously
              from multiple locations
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessChannel;
