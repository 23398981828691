import React from "react";
import Play from "../assets/google play.png";
import Apple from "../assets/apple logo.png";

const StoreBtn = () => {
  return (
    <div className="h-[75%]">
      <div className="flex ">
        <div className="grid grid-cols-3 bg-black h-14 rounded-md mr-2 w-40 px-2 py-2">
          <img className="h-6 mx-auto mt-1" src={Apple} alt="/" />
          <div className="col-span-2 flex flex-col text-white">
            <p className="text-xs">Download on the</p>
            <p className="text-sm font-semibold">Play Store</p>
          </div>
        </div>
        <div className="grid grid-cols-3 bg-black h-14 rounded-md w-40 px-2 py-2">
          <img className="h-6 mx-auto mt-2" src={Play} alt="/" />
          <div className="col-span-2 flex flex-col text-white">
            <p className="text-xs">GET IT ON</p>
            <p className="text-sm font-semibold">Google Play</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreBtn;
