import React, { useState } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

const baseURL = process.env.REACT_APP_BASE_URL;
const postUrl = `${baseURL}/contact-us`;

const GetStarted = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate();
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      axios
        .post(postUrl, {
          first_name: firstname,
          last_name: lastname,
          email: email,
          phone_number: phoneNumber,
          message: message,
        })
        .then((response) => {
          window.location.href = "/personal?fromGetStarted=true";
          setIsLoading(true)
        })
        .catch((error) => {
          handle422(error);
          handle400(error);
          handle500(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setErrors(errors);
    }
  };

  function handle422(e) {
    let r = e.response;
    if (r.status === 422) {
      let error_bag = r.data.data;

      const keys = Object.keys(error_bag);
      let first_error_name = keys[0];
      let first_error_value = error_bag[first_error_name];

      toast.error(first_error_value[0], {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "light",
      });
    }
  }

  function handle400(e) {
    let r = e.response;
    if (r.status === 400) {
      var str = r.data.message;
      str = str.replace(/_/g, " ");

      toast.error(str, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "light",
      });
    }
  }

  function handle500(e) {
    let r = e.response;
    if (r.status === 500) {
      console.error("Internal Server Error: ", r.data);
      toast.error("An error occurred on the server. Please try again later.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "light",
      });
    }
  }

  const validate = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{11}$/;
    if (!firstname.trim()) {
      errors.firstname = "Your first name is required";
    }
    if (!lastname.trim()) {
      errors.lastname = "Your last name is required";
    }

    if (!emailRegex.test(email)) {
      errors.email = "Please enter a valid email";
    }
    if (!phoneRegex.test(phoneNumber)) {
      errors.phoneNumber = "Please enter a valid phone number";
    }
    if (!message.trim()) {
      errors.message = "Message is required";
    }
    return errors;
  };

  return (
    <div className="bg-gray-100 py-12">
      <div className="max-w-xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-2xl font-bold mb-6">Contact Us</h2>
        <h2 className="font-semibold mb-6">
          To get started as a BillerHQ merchant, fill out the form below and a member of our team will
          reach out to you.
        </h2>
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <form onSubmit={handleSubmit} className="mb-4">
            <div className="mb-4">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="firstname"
              >
                First Name
              </label>
              <input
                className={`shadow appearance-none border ${
                  errors.firstname ? "border-red-500" : "border-gray-400"
                } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                id="firstname"
                type="text"
                placeholder="Your First Name"
                value={firstname}
                onChange={(e) => setFirstName(e.target.value)}
              />
              {errors.firstname && (
                <span className="text-red-500 text-xs">{errors.firstname}</span>
              )}
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="lastname"
              >
                Last Name
              </label>
              <input
                className={`shadow appearance-none border ${
                  errors.lastname ? "border-red-500" : "border-gray-400"
                } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                id="lastname"
                type="text"
                placeholder="Your Last Name"
                value={lastname}
                onChange={(e) => setLastName(e.target.value)}
              />

              {errors.lastname && (
                <span className="text-red-500 text-xs">{errors.lastname}</span>
              )}
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="email"
              >
                Email
              </label>
              <input
                className={`shadow appearance-none border ${
                  errors.email ? "border-red-500" : "border-gray-400"
                } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                id="email"
                type="email"
                placeholder="Your Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && (
                <span className="text-red-500 text-xs">{errors.email}</span>
              )}
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="phoneNumber"
              >
                Phone Number
              </label>
              <input
                className={`shadow appearance-none border ${
                  errors.phoneNumber ? "border-red-500" : "border-gray-400"
                } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                id="phoneNumber"
                type="text"
                placeholder="Your Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              {errors.phoneNumber && (
                <span className="text-red-500 text-xs">
                  {errors.phoneNumber}
                </span>
              )}
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="message"
              >
                Message
              </label>
              <textarea
                className={`shadow appearance-none border ${
                  errors.message ? "border-red-500" : "border-gray-400"
                } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                id="message"
                rows="4"
                placeholder="Your Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
              {errors.message && (
                <span className="text-red-500 text-xs">{errors.message}</span>
              )}
            </div>

            <ReCAPTCHA sitekey="6LcZeZQlAAAAALt0WrQiWXfH5M6DAypgMx9tSWxg" />
            <div className="flex items-center justify-between">
              <button
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  `Submit`
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
