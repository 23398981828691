import React from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Laptop from "../assets/laptop.jpg";
import { FaInstagram, FaLinkedinIn } from "react-icons/fa";

const CtaSection = () => {
  AOS.init();
  return (
    <div className="w-full text-[#333333] px-2">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-3 px-4">
        <div
          data-aos="fade-right"
          data-aos-delay="1000"
          data-aos-duration="800"
          className="flex flex-col justify-center"
        >
          <div className="w-full flex justify-between text-white p-2 py-4 mt-8 bg-green-700 rounded-lg "></div>
          <div className="mt-4">
            <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold">
              Pay Bills{" "}
            </h1>
            <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold">
              from the comfort{" "}
            </h1>
            <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold">
              of your Phone
            </h1>
            <p className="pt-2">
              Want to pay bills from the comfort of your phone? Simply use the
              Uniswitch payment platform to make all recurring online payment.
            </p>
          </div>
          <div className="flex flex-col justify-center items-center md:flex md:items-start mb-4">
            <Link
              to="personal"
              className="bg-black hover:bg-green-500 text-white w-full md:w-[200px] rounded-lg font-medium my-6 mx-auto lg:mx-0 py-3 text-center"
            >
              GET STARTED
            </Link>
            <div className="flex w-full md:w-[20%]">
              <div className="text-[#909292] mx-2 hover:text-green-500">
                <a
                  href="https://www.instagram.com/uniswitchng/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram size={30} />
                </a>
              </div>

              <div className="text-[#909292] mx-2 hover:text-green-500">
                <a
                  href="https://www.linkedin.com/company/uniswitch/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedinIn size={30} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <img
          data-aos="fade-left"
          data-aos-delay="1200"
          data-aos-duration="800"
          className="w-[500px] mx-auto my-4 md:col-span-2"
          src={Laptop}
          alt="/"
        />
      </div>
    </div>
  );
};

export default CtaSection;
