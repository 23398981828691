import React, { useEffect } from "react";
import AppRoutes from "./components/AppRoutes";
import Tracker from '@openreplay/tracker';

const projectKey = process.env.REACT_APP_PROJECT_KEY;


const tracker = new Tracker({
  projectKey: projectKey,
});

const App = () => {
  useEffect(() => {
    tracker.start();
  }, [])
  
  return <AppRoutes />;
};

export default App;
