import React from "react";

const Faq = () => {
  return (
    <div className="text-[#333333] lg:w-[1140px]  md:mx-auto px-8 py-16 mt-6">
    <h1 className="text-green-700  mx-auto md:text-2xls text-xl mb-4">
        FAQ{" "}
      </h1>
      <h1
        data-aos="fade-in"
        data-aos-delay="800"
        data-aos-duration="400"
        className="mx-auto md:text-3xls text-2xl font-bold mb-4"
      >
        Frequently Asked Questions
      </h1>

      <div className="mx-auto grid sm:grid-cols-2 md:grid-cols-2">
        <div
          className="flex flex-col justify-center"
        >
          <div className="my-6 bg-green-600 text-white p-4 rounded-lg">
            <h1 className="lg:text-2xls text-xl font-bold mb-4">
              How can I buy electricity?{" "}
            </h1>
            <p className="pt-2">
              To buy electricity, click on ‘electricity’, and select your
              distribution company and your meter type, meter number, amount and
              other requested information. Review to confirm that your
              information is correct, submit and proceed to payment.
            </p>
          </div>
          <div className="my-6 p-4">
            <h1 className="mb-4 lg:text-2xls text-xl  font-bold">
              How can I buy data?{" "}
            </h1>
            <p className="pt-2">
              To buy data, click on ‘internet’, select your network provider and
              bundle. Input your phone number and email then proceed to payment.
            </p>
          </div>
          <div className="my-6 bg-green-600 text-white p-4 rounded-lg">
            <h1 className="mb-4 lg:text-2xls text-xl  font-bold">
              {" "}
              Do I have to create or have an account before I can make purchases
              on BillerHQ?{" "}
            </h1>
            <p className="pt-2">
              No, you do not have to create an account or have one to use
              BillerHQ services.
            </p>
          </div>
        </div>
        <div
          className="flex flex-col justify-center"
        >
          <div className="my-6 p-4">
            <h1 className="mb-4 lg:text-2xls text-xl font-bold">
              {" "}
              How can I onboard as a Merchant?{" "}
            </h1>
            <p className="pt-2">
              To onboard as a merchant, use our ‘get started’ button to contact
              us. After filling out and submitting the form, a member of our
              team will contact you and inform you on how to proceed.{" "}
            </p>
          </div>
          <div className="my-6 bg-green-600 text-white p-4 rounded-lg">
            <h1 className="mb-4 lg:text-2xls text-xl  font-bold">
              {" "}
              Do I get discounts on transactions?{" "}
            </h1>
            <p className="pt-2">
              Yes. You get a discount of %10 capped at N100 for transactions
              exceeding N10,000 daily.{" "}
            </p>
          </div>
          <div className="my-6 p-4">
            <h1 className="mb-4 lg:text-2xls text-xl  font-bold">
              {" "}
              How can I contact customer support to log a complaint?{" "}
            </h1>
            <p className="pt-2">
              To contact customer support to log a complaint or for other
              reasons, use the chat widget at the bottom right corner to send a
              message and our support team will attend to you immediately
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
