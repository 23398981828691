import React from "react";
import Sidenav from "../../components/Sidenav";
import BuyAirtime from "./BuyAirtime";

const Airtime = () => (
  <>
    <div className="sm:flex">
      <div className="sm:w-1/5 md:block sm:mr-2">
        <Sidenav />
      </div>
      <div className="sm:w-4/5 mx-auto px-2 sm:mr-2">
        <BuyAirtime />
      </div>
    </div>
  </>
);

export default Airtime;
