import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/billerHQ-04.png";

const Nav = () => {
  let location = useLocation();
  let currentLocation = location.pathname;
  currentLocation = currentLocation.slice(1);

  const [navOpen, setNavOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(currentLocation);

  const handleNav = () => setNavOpen(!navOpen);

  return (
    <>
      <nav className="bg-[#ffffff] top-6 left-0 w-full h-12 z-40 flex items-center border-b-2 justify-between px-8">
        <Link to="/" className="px-2 py-2 flex items-center"   onClick={() => {
              setActiveTab("");
            }}>
          <img src={Logo} alt="Logo" className="h-8 sm:ml-6" />
        </Link>
        <div className="hidden md:flex">
          <Link
            to="/personal"
            className={`px-4 py-1 text-black hover:text-green-600 font-medium rounded-md ${
              activeTab === "personal" ? "bg-green-100" : ""
            }`}
            onClick={() => {
              setActiveTab("personal");
            }}
          >
            Personal
          </Link>
          <Link
            to="/business"
            className={`px-4 py-1 text-black hover:text-green-600 font-medium rounded-md ${
              activeTab === "business" ? "bg-green-100" : ""
            }`}
            onClick={() => {
              setActiveTab("business");
            }}
          >
            Business
          </Link>
        </div>
        <button
          className="px-4 py-2 text-green-700 font-medium block md:hidden"
          onClick={handleNav}
        >
          {navOpen ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
        </button>
        <div className="hidden md:flex md:mr-4">
          <Link
            to="/personal"
            className="bg-green-700 hover:bg-green-500 w-[100px] rounded-lg text-white px-2 my-2 mx-2 py-1 $"
            onClick={() => {
              setActiveTab("personal");
            }}
          >
            Get Started
          </Link>
        </div>
      </nav>

      <div
        className={`fixed top-12 left-0 bg-white w-[100%] h-screen z-40 overflow-y-auto ${
          navOpen ? "block" : "hidden"
        }`}
      >
        <div className="px-2 py-6">
          {/* <Link to="/" className="px-4 py-2 flex items-center">
          <img src={Logo} alt="Logo" className="h-8 ml-6"  onClick={handleNav} />
        </Link> */}
          <Link
            to="/personal"
            className={`block p-2 text-black font-medium rounded-md ${
              activeTab === "personal" ? "bg-green-200" : ""
            }`}
            onClick={() => {
              setActiveTab("personal");
              handleNav();
            }}
          >
            Personal
          </Link>
          <Link
            to="/business"
            className={`block p-2 text-black font-medium rounded-md ${
              activeTab === "business" ? "bg-green-200" : ""
            }`}
            onClick={() => {
              setActiveTab("business");
              handleNav();
            }}
          >
            Business
          </Link>

          <div className="mt-2">
            <Link
              to="/personal"
              className={`block p-2 text-black font-medium rounded-md text-center bg-green-500 hover:bg-green-700 w-full] px-2 my-2 mx-2 py-2 ${
                activeTab === "business" ? "bg-green-200" : ""
              }`}
              onClick={() => {
                setActiveTab("personal");
                handleNav();
              }}
            >
              Get Started
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Nav;
