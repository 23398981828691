import React from 'react'
import CtaSection from '../components/CtaSection'
import Newsletter from '../components/Newsletter'
import Nav from '../components/Nav'

const Home = (props) => {
  return (
    <div>
      <Nav />
        <CtaSection />
        <Newsletter />  
    </div>
  )
}

export default Home;