import { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import ReactGA from "react-ga4";


const baseURL = process.env.REACT_APP_BASE_URL;

const serviceUrl = `${baseURL}/services?type=Energy`;
const lookUpUrl = `${baseURL}/lookup`;
const verifyRequestUrl = `${baseURL}/bills-payment/energy/verify`;
const vendUrl = `${baseURL}/bills-payment/energy/vend`;

const Electricity = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [meterLoading, setMeterLoading] = useState(false);
  const [serviceLoading, setServiceLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formStep, setFormStep] = useState(0);

  const [state, setState] = useState({
    providers: [],
    meters: [],
  });

  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    meterNumber: "",
    meterType: "",
    disco: "",
    discoName: "",
    amount: "",
    phoneNumber: "",
    email: "",
    parsedAmount: "",
  });

  const [customerInformation, setCustomerInformation] = useState({
    customerName: "",
    customerAddress: "",
  });

  function ensureIsArray(responseData) {
    if (Array.isArray(responseData)) {
      return responseData;
    } else {
      return Object.keys(responseData).map((key) => {
        return { ...responseData[key] };
      });
    }
  }

  const validate = () => {
    let errors = {};

    if (!formData.meterNumber) {
      errors.meterNumber = "meter number is required";
    }

    if (!formData.meterType) {
      errors.meterType = "meter type number is required";
    }

    if (!formData.phoneNumber) {
      errors.phoneNumber = "phone number is required";
    } else if (!/^\d{11}$/.test(formData.phoneNumber)) {
      errors.phoneNumber = "phone number must be 11 digits";
    }

    if (!formData.email) {
      errors.email = "email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "email is invalid";
    }

    if (!formData.disco) {
      errors.disco = "disco is required";
    }

    if (!formData.amount) {
      errors.amount = "amount is required";
    }

    return errors;
  };

  const next = () => {
    setFormStep((currentStep) => currentStep + 1);
  };

  const back = () => {
    setFormStep((currentStep) => currentStep - 1);
  };

  function formatNumber(number) {
    return parseInt(number).toLocaleString();
  }

  function resetProductTree() {
    setState((state) => {
      return { ...state, products: [], pricingOptions: [] };
    });
  }

  useEffect(() => {
    setServiceLoading(true);
    axios
      .get(serviceUrl)
      .then((response) => {
        const providersData = response.data.data;
        setState((state) => {
          return { ...state, providers: ensureIsArray(providersData) };
        });
        setServiceLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });

    setMeterLoading(true);
    axios
      .get(lookUpUrl)
      .then((response) => {
        const meterData = response.data.data;
        const meterType = meterData.meter_types;
        setState((state) => {
          return { ...state, meters: ensureIsArray(meterType) };
        });
        setMeterLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleServiceChange = (e) => {
    setErrors({});
    const serviceId = e.target.value;
    const selectedOption = e.target.options[e.target.selectedIndex];
    const serviceName = selectedOption.getAttribute("data-provider-name");
    resetProductTree();
    setFormData((prevData) => ({
      ...prevData,
      disco: serviceId,
      discoName: serviceName,
    }));
  };
  const handleMeterChange = (e) => {
    setErrors({});
    const service = e.target.value;
    resetProductTree();
    setFormData((prevData) => ({
      ...prevData,
      meterType: service,
    }));
  };

  const handleChange = (e) => {
    setErrors({});
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    setErrors({});

    const errors = validate();

    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      axios
        .post(verifyRequestUrl, {
          service_id: formData.disco,
          meter_number: formData.meterNumber,
          meter_type: formData.meterType,
          amount: formData.amount,
        })
        .then((response) => {
          const customerData = response.data;
          const customerInformation = customerData.data.customer_information;
          const customerName = customerInformation.name;
          const customerAddress = customerInformation.address;

          setCustomerInformation({
            ...customerInformation,
            customerName: customerName,
            customerAddress: customerAddress,
          });
          setFormStep(1);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          handle422(error);
          handle400(error);
          handle500(error);
        });
    } else {
      setErrors(errors);
    }
  };

  const submitToBackend = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const serviceId = formData.disco;
    axios
      .post(vendUrl, {
        service_id: serviceId,
        meter_number: formData.meterNumber,
        meter_type: formData.meterType,
        amount: formData.parsedAmount,
        phone_number: formData.phoneNumber,
        email: formData.email,
      })
      .then((response) => {
        const redirectUrl = response.data.data.url;
        window.location.href = redirectUrl;
      })
      .catch((error) => {
        handle422(error);
        handle400(error);
        handle500(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
      ReactGA.event({
        category: "Form",
        action: "Submit",
        label: "buy energy",
      });
  };

  function handle422(e) {
    let r = e.response;
    if (r.status === 422) {
      if (formStep === 1) {
        back();
      }
      let error_bag = r.data.data;

      const keys = Object.keys(error_bag);
      let first_error_name = keys[0];
      let first_error_value = error_bag[first_error_name];


      toast.error(first_error_value[0], {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "light",
      });
    }
  }

  function handle400(e) {
    let r = e.response;
    if (r.status === 400) {
      if (formStep === 1) {
        back();
      }

      var str = r.data.message;
      str = str.replace(/_/g, " ");

      toast.error(str, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "light",
      });
    }
  }

  function handle500(e) {
    let r = e.response;
    if (r.status === 500) {
      if (formStep === 1) {
        back();
      }
      console.error("Internal Server Error: ", r.data);
      toast.error("An error occurred on the server. Please try again later.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "light",
      });
    }
  }

  const renderButton = () => {
    if (formStep === 0) {
      return (
        <div className="flex flex-col items-center">
          <button
            disabled={isLoading}
            type="submit"
            className="mt-2 px-4 py-2 w-3/4 disabled:bg-gray-400 disabled:cursor-not-allowed bg-green-700 hover:bg-green-600 rounded-sm text-white font-bold text-xl"
          >
            {isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : `Submit`}
          </button>
        
        </div>
      );
    } else if (formStep === 1) {
      return (
        <div className="flex flex-col items-center">
          <button
            disabled={isLoading}
            onClick={submitToBackend}
            type="button"
            className="mt-2 px-4 py-2 w-3/4 disabled:bg-gray-400 disabled:cursor-not-allowed bg-green-700 hover:bg-green-600 rounded-sm text-white font-bold text-xl"
          >
            {isLoading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              `Pay   ₦ ${formData.amount}`
            )}
          </button>
         
        </div>
      );
    } else {
      return null;
    }
  };

  const handleKeyPress = (e) => {
    let input = e.target.value;

    if (input.trim() === "") {
      return;
    }

    if (input.includes(",")) {
      input = input.replace(/,/g, "");
    }

    let num = parseFloat(input);

    const parse = num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    const splittedValue = parse.split(".")[0];
    setFormData((prevData) => {
      return {
        ...prevData,
        amount: splittedValue,
        parsedAmount: parseInt(splittedValue.replace(/,/g, "")),
      };
    });
  };

  return (
    <>
      <ToastContainer />
      <div className="w-full sm:w-4/5 md:4/5 lg:w-2/5 mx-auto">
        <div className="w-[25rem] flex justify-around mt-8 mx-auto">
          <button
            className="w-6 h-6 bg-gray-300 rounded-full text-white transition duration-300 hover:bg-green-500 disabled:bg-grey-500"
            style={{
              backgroundColor:
                formStep === 0 ? "green" : formStep === 1 ? "green" : "green",
            }}
            onClick={() => setFormStep(0)}
          ></button>
          <button
            className="w-6 h-6 bg-gray-300 rounded-full text-white transition duration-300 hover:bg-green-500 disabled:bg-grey-500"
            style={{
              backgroundColor:
                formStep === 0 ? "gray" : formStep === 1 ? "green" : "green",
            }}
            onClick={onSubmit}
            disabled={formStep !== 0 || isButtonDisabled}
          ></button>
          <button
            className="w-6 h-6 bg-gray-300 rounded-full text-white transition duration-300 hover:bg-green-500 disabled:bg-grey-500"
            style={{
              backgroundColor:
                formStep === 0 ? "gray" : formStep === 1 ? "gray" : "green",
            }}
          ></button>
        </div>

        <div className="w-[25rem] flex justify-around mx-auto">
          <p className=" text-gray-500 text-xs rounded-full transition duration-300 mt-2">
            Enter Information
          </p>
          <p className=" text-gray-500 text-xs rounded-full transition duration-300 mt-2">
            Make Payment
          </p>
          <p className=" text-gray-500 text-xs rounded-full transition duration-300 mt-2">
            View Receipt
          </p>
        </div>
        <div className="w-[25rem] mx-auto px-16 mb-16 -mt-9">
          <div
            className="w-full h-[1px] bg-green-500"
            style={{
              width: formStep === 0 ? "0%" : formStep === 1 ? "50%" : "100%",
            }}
          ></div>
          <div className="w-full h-[1px] bg-gray-200"></div>
        </div>

        <div className="max-w-xl mt-4 mb-4 rounded-lg bg-white overflow-hidden z-10">
          <form onSubmit={onSubmit}>
            {formStep === 0 && (
              <section>
                <h2 className="font-bold text-center text-2xl mb-3">
                  Buy Electricity
                </h2>
                <p className="text-black font-semibold text-sm text-center mb-6 mt-2">
                  Want to pay electricity bills? You can buy power units easily
                  here
                </p>

                <div className="mb-1 relative">
                  <label
                    className="block text-gray-400 text-xs"
                    htmlFor="disco"
                  >
                    Disco
                    {serviceLoading && (
                      <div className="absolute right-8 top-7 text-black">
                        <FontAwesomeIcon icon={faSpinner} spin />
                      </div>
                    )}
                    <select
                      id="disco"
                      className="w-full appearance-none border border-gray-300 p-3 rounded-lg"
                      name="disco"
                      onChange={handleServiceChange}
                      value={formData.disco}
                    >
                      <option disabled value="">
                        select disco
                      </option>
                      {state.providers.map((provider) => (
                        <option
                          key={provider.id}
                          data-provider-name={provider.description}
                          value={provider.id}
                        >
                          {provider.description}
                        </option>
                      ))}
                    </select>
                    {errors.disco && (
                      <span className="text-red-500 text-sm">
                        {errors.disco}
                      </span>
                    )}
                  </label>
                </div>

                <div className="flex">
                  <div className="mb-1 w-1/2">
                    <label
                      className="block text-gray-400 text-xs"
                      htmlFor="meterNumber"
                    >
                      Meter Number
                    </label>
                    <input
                      type="number"
                      id="meterNumber"
                      name="meterNumber"
                      onChange={handleChange}
                      value={formData.meterNumber}
                      className="w-full border border-gray-300 p-3 rounded-lg text-xs"
                    />
                    {errors.meterNumber && (
                      <span className="text-red-500 text-sm">
                        {errors.meterNumber}
                      </span>
                    )}
                  </div>
                  <div className="mb-1 w-1/2 ml-2 relative">
                    <label
                      htmlFor="meterTypeSelect"
                      className="block text-gray-400 text-xs"
                    >
                      Meter Type
                      {meterLoading && (
                        <div className="absolute right-8 top-7 text-black">
                          <FontAwesomeIcon icon={faSpinner} spin />
                        </div>
                      )}
                      <select
                        id="meterType"
                        name="meterType"
                        onChange={handleMeterChange}
                        value={formData.meterType}
                        className="w-full appearance-none border border-gray-300 p-3 rounded-lg h-full"
                        placeholder="select"
                        size="1"
                      >
                        <option disabled value="">select meter</option>
                        {state.meters.map((meter) => (
                          <option key={meter} value={meter}>
                            {meter}
                          </option>
                        ))}
                      </select>
                      {errors.meterType && (
                        <span className="text-red-500 text-sm">
                          {errors.meterType}
                        </span>
                      )}
                    </label>
                  </div>
                </div>

                <div className="flex">
                  <div className="mb-1 w-1/2">
                    <label
                      className="block text-gray-500 text-xs"
                      htmlFor="amount"
                    >
                      Amount
                    </label>
                    <div className="flex items-center border border-gray-300 p-2 rounded-lg">
                      <span className="mr-2 text-xs p-1">₦</span>
                      <input
                        type="text"
                        id="amount"
                        className="w-full outline-none text-xs"
                        name="amount"
                        onKeyUp={handleKeyPress}
                        onChange={handleChange}
                        value={formData.amount}
                      />
                    </div>
                    {errors.amount && (
                      <span className="text-red-500 text-sm">
                        {errors.amount}
                      </span>
                    )}
                  </div>
                  <div className="mb-3 w-1/2 ml-2">
                    <label
                      className="block text-gray-500 text-xs"
                      htmlFor="phoneNumber"
                    >
                      Phone Number
                    </label>
                    <input
                      type="number"
                      id="phoneNumber"
                      className="w-full border border-gray-300 p-3 rounded-lg text-xs"
                      name="phoneNumber"
                      placeholder="08000000000"
                      onChange={handleChange}
                      value={formData.phoneNumber}
                    />
                    {errors.phoneNumber && (
                      <span className="text-red-500 text-sm">
                        {errors.phoneNumber}
                      </span>
                    )}
                  </div>
                </div>

                <div className="mb-1">
                  <label
                    className="block text-gray-400 text-xs"
                    htmlFor="email"
                  ></label>
                  <input
                    placeholder="Email Address"
                    type="email"
                    id="email"
                    name="email"
                    onChange={handleChange}
                    value={formData.email}
                    className="w-full border border-gray-300 text-xs p-3 rounded-lg"
                  />
                  {errors.email && (
                    <span className="text-red-500 text-sm">{errors.email}</span>
                  )}
                </div>
              </section>
            )}

            {formStep === 1 && (
              <section>
                <h2 className="font-bold text-center text-2xl mb-3">
                  Review Information
                </h2>
                <p className="text-black font-semibold text-sm text-center mt-2">
                  Review information and make payment
                </p>
                <div className="rounded mx-auto py-1 px-8 w-full h-full flex items-center align-items-center">
                  <div className="border mx-auto border-green-400 rounded-xl shadow-md px-24 py-6">
                    <div className="text-center">
                      <p className="font-bold text-xs mt-2"> NAME:</p>
                      <p className="text-xs">
                        {customerInformation.customerName}
                      </p>
                      <p className="font-bold text-xs mt-2">METER NUMBER:</p>
                      <p className="text-xs">{formData.meterNumber}</p>
                      <p className="font-bold text-xs mt-2">DISCO:</p>
                      <p className="text-xs">{formData.discoName}</p>
                      <p className="font-bold text-xs mt-2">ADDRESS:</p>
                      <p className="text-xs">
                        {customerInformation.customerAddress}
                      </p>
                      <p className="font-bold text-xs mt-2">AMOUNT:</p>
                      <p className="text-xs">₦ {formData.amount}</p>
                    </div>
                  </div>
                </div>
              </section>
            )}

            {renderButton()}
          </form>
        </div>
      </div>
    </>
  );
};

export default Electricity;
