import React, { useState, useEffect } from "react";
import "./Tvsub.css";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import ReactGA from "react-ga4";


const baseURL = process.env.REACT_APP_BASE_URL;
const serviceUrl = `${baseURL}/services?type=Cable`;
const verifyRequestUrl = `${baseURL}/bills-payment/cable/verify`;
const vendUrl = `${baseURL}/bills-payment/cable/vend`;

const TvSubscription = () => {
  const [formStep, setFormStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [serviceSpinner, setServiceSpinner] = useState(false);
  const [customerSpinner, setCustomerSpinner] = useState(false);

  const next = () => {
    setFormStep((currentStep) => currentStep + 1);
  };

  const back = () => {
    setFormStep((currentStep) => currentStep - 1);
  };

  const [formData, setFormData] = useState({
    networkProvider: "",
    networkName: "",
    product: "",
    productName: "",
    pricing: "",
    smartcardNumber: "",
    phoneNumber: "",
    email: "",
    monthspaidfor: "",
  });

  const [customerInformation, setCustomerInformation] = useState({
    customerName: "",
    decoderNumber: "",
  });

  const [errors, setErrors] = useState({});

  const validate = () => {
    let errors = {};

    if (!formData.networkProvider) {
      errors.networkProvider = "provider is required";
    }

    if (!formData.smartcardNumber) {
      errors.smartcardNumber = "smartcard number is required";
    }

    if (!formData.phoneNumber) {
      errors.phoneNumber = "phone number is required";
    } else if (!/^\d{11}$/.test(formData.phoneNumber)) {
      errors.phoneNumber = "phone number must be 11 digits";
    }

    if (!formData.email) {
      errors.email = "email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "email is invalid";
    }

    if (!formData.product) {
      errors.product = "product is required";
    }

    if (!formData.pricing) {
      errors.pricing = "pricing is required";
    }

    return errors;
  };

  const onSubmit = (e) => {
    e.preventDefault();

    setErrors({});

    const errors = validate();

    if (Object.keys(errors).length === 0) {
      setCustomerSpinner(true);
      axios
        .post(verifyRequestUrl, {
          service_id: formData.networkProvider,
          decoder_number: formData.smartcardNumber,
        })
        .then((response) => {
          const customerData = response.data;
          const customerInformation = customerData.data.customer_information;
          const customerName = customerInformation.name;
          const customerDecoderNumber = customerInformation.decoder_number;

          setCustomerInformation({
            ...customerInformation,
            customerName: customerName,
            decoderNumber: customerDecoderNumber,
          });
          setCustomerSpinner(false);
        })
        .catch((error) => {
          console.error(error);
        });

      setFormStep(1);
    } else {
      setErrors(errors);
    }
  };

  const [data, setData] = useState({
    providers: [],
    products: [],
    pricingOptions: [],
  });

  const bundleName = (bundle) => {
    switch (bundle) {
      case "PRWFRNSE36":
        return "DStv Premium French";
      case "PRWE36":
        return "DStv Premium";
      case "PRWASIE36":
        return "DStv Premium Asia";
      case "ASIAE36":
        return "Asian Bouqet";
      case "NNJ2E36":
        return "DStv Confam Bouquet E36";
      case "NNJ1E36":
        return "DStv Yanga Bouquet E36";
      case "COMPE36":
        return "DStv Compact";
      case "COMPLE36":
        return "DStv Compact Plus";
      case "NLTESE36":
        return "Padi";
      default:
        return null;
    }
  };

  function ensureIsArray(responseData) {
    if (Array.isArray(responseData)) {
      return responseData;
    } else {
      return Object.keys(responseData).map((key) => {
        return { ...responseData[key] };
      });
    }
  }

  function pluralMonths(month) {
    return month > 1 ? month + " months" : month + " month";
  }

  function formatNumber(number) {
    return parseInt(number).toLocaleString();
  }

  function splitAmount(number) {
    let [price, months] = formData.pricing.split("-");
    return price;
  }

  function resetProductTree() {
    setData((state) => {
      return { ...state, products: [], pricingOptions: [] };
    });
  }

  const renderButton = () => {
    if (formStep === 0) {
      return (
        <div className="flex flex-col items-center">
          <button
            type="submit"
            className="mt-2 px-4 py-2 w-3/4 disabled:bg-gray-400 disabled:cursor-not-allowed bg-green-700 hover:bg-green-600 rounded-sm text-white font-bold text-xl"
          >
            Submit
          </button>
        
        </div>
      );
    } else if (formStep === 1) {
      return (
        <div className="flex flex-col items-center">
          <button
            disabled={isLoading}
            onClick={submitToBackend}
            type="button"
            className="mt-2 px-4 py-2 w-3/4 disabled:bg-gray-400 disabled:cursor-not-allowed bg-green-700 hover:bg-green-600 rounded-sm text-white font-bold text-xl"
          >
            {isLoading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              `Pay   ₦ ${formatNumber(splitAmount(formData.pricing))}`
            )}
          </button>
        
        </div>
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    axios
      .get(serviceUrl)
      .then((response) => {
        const providersData = response.data.data;
        setData((state) => {
          return { ...state, providers: ensureIsArray(providersData) };
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleServiceChange = (e) => {
    setErrors({});
    const serviceId = e.target.value;
    const selectedOption = e.target.options[e.target.selectedIndex];
    const serviceName = selectedOption.getAttribute("data-provider-name");
    resetProductTree();
    setFormData((prevData) => ({
      ...prevData,
      networkProvider: serviceId,
      networkName: serviceName,
    }));
    setServiceSpinner(true);
    axios
      .get(`${baseURL}/services/${serviceId}/products`)
      .then((response) => {
        setServiceSpinner(false);
        let data = response.data;
        if (data.data) {
          if (data.status === "success") {
            setData((state) => {
              return { ...state, products: ensureIsArray(data.data) };
            });
          }
          return;
        }
        console.log("An error occured while fetching product.");
      })
      .catch((error) => {
        setServiceSpinner(false);
        console.error(error);
      });
  };

  const handleProductChange = (event) => {
    setErrors({});
    let productId = event.target.value;
    let productName = bundleName(productId);

    let product = data.products.filter((product) => {
      return product.id === productId;
    });

    setFormData((prevData) => ({
      ...prevData,
      product: productId,
      productName,
      pricing: "",
    }));

    setData((state) => {
      return {
        ...state,
        pricingOptions: ensureIsArray(product[0]["pricingOptions"]),
      };
    });
  };

  const handlePricingChange = (event) => {
    setErrors({});
    let pricing = event.target.value;

    setFormData((prevData) => ({
      ...prevData,
      pricing: pricing,
    }));
  };

  const handleChange = (e) => {
    setErrors({});
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const submitToBackend = (e) => {
    e.preventDefault();
    setIsLoading(true);

    let [price, months] = formData.pricing.split("-");

    axios
      .post(`${vendUrl}`, {
        product_id: formData.product,
        service_id: formData.networkProvider,
        decoder_number: customerInformation.decoderNumber,
        pricingOption: months,
        amount: price,
        phone_number: formData.phoneNumber,
        email: formData.email,
      })
      .then((response) => {
        const redirectUrl = response.data.data.url;
        window.location.href = redirectUrl;
      })
      .catch((error) => {
        handle422(error);
        handle400(error);
        handle500(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
      ReactGA.event({
        category: "Form",
        action: "Submit",
        label: "buy cable",
      });
  };

  function handle422(e) {
    let r = e.response;
    if (r.status === 422) {
      back();

      let error_bag = r.data.data;

      const keys = Object.keys(error_bag);
      let first_error_name = keys[0];
      let first_error_value = error_bag[first_error_name];

      console.log(toast, "The toast object");

      toast.error(first_error_value[0], {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "light",
      });
    }
  }

  function handle400(e) {
    let r = e.response;
    if (r.status === 400) {
      back();

      console.error("Bad Request: ", r.data);
      console.log(toast, "The toast object");

      toast.error(r.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "light",
      });
    }
  }

  function handle500(e) {
    let r = e.response;
    if (r.status === 500) {
      back();
      console.error("Internal Server Error: ", r.data);
      toast.error("An error occurred on the server. Please try again later.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "light",
      });
    }
  }

  return (
    <>
      <ToastContainer />
      <div className="w-full sm:w-4/5 md:4/5 lg:w-2/5 mx-auto">
        <div className="w-[25rem] flex justify-around mt-8 mx-auto">
          <button
            className="w-6 h-6 bg-gray-300 rounded-full text-white transition duration-300 hover:bg-green-500 disabled:bg-grey-500"
            style={{
              backgroundColor:
                formStep === 0 ? "green" : formStep === 1 ? "green" : "green",
            }}
            onClick={() => setFormStep(0)}
          ></button>
          <button
            className="w-6 h-6 bg-gray-300 rounded-full text-white transition duration-300 hover:bg-green-500 disabled:bg-grey-500"
            style={{
              backgroundColor:
                formStep === 0 ? "gray" : formStep === 1 ? "green" : "green",
            }}
            onClick={onSubmit}
            disabled={formStep !== 0}
          ></button>
          <button
            className="w-6 h-6 bg-gray-300 rounded-full text-white transition duration-300 hover:bg-green-500 disabled:bg-grey-500"
            style={{
              backgroundColor:
                formStep === 0 ? "gray" : formStep === 1 ? "gray" : "green",
            }}
          ></button>
        </div>
        <div className="w-[25rem] flex justify-around mx-auto">
          <p className=" text-gray-500 text-xs rounded-full transition duration-300 mt-2">
            Enter Information
          </p>
          <p className=" text-gray-500 text-xs rounded-full transition duration-300 mt-2">
            Make Payment
          </p>
          <p className=" text-gray-500 text-xs rounded-full transition duration-300 mt-2">
            View Receipt
          </p>
        </div>
        <div className="w-[25rem] mx-auto px-16 mb-16 -mt-9">
          <div
            className="w-full h-[1px] bg-green-500"
            style={{
              width: formStep === 0 ? "0%" : formStep === 1 ? "50%" : "100%",
            }}
          ></div>
          <div className="w-full h-[1px] bg-gray-200"></div>
        </div>

        <div className="max-w-xl w-full mt-4 mb-4 rounded-lg bg-white mx-auto overflow-hidden z-10">
          <form onSubmit={onSubmit}>
            {formStep === 0 && (
              <section>
                <h2 className="font-bold text-center text-2xl mb-3">
                  TV Subscription
                </h2>
                <p className="text-black font-semibold text-sm text-center mb-6 mt-2">
                  Cable tv subscription is easy. You can pay for tv subscription
                  here
                </p>

                <div className="service-provider-wrap">
                  <div className="mb-2">
                    <label
                      className="block text-gray-500 text-xs"
                      htmlFor="NetworkProvider"
                    >
                      Service Provider
                      <select
                        id="networkProvider"
                        name="networkProvider"
                        onChange={handleServiceChange}
                        value={formData.networkProvider}
                        className="w-full appearance-none border border-gray-300 text-gray-700 p-3 rounded-lg"
                      >
                        <option disabled value="">
                          select a provider
                        </option>
                        {data.providers.map((provider) => (
                          <option
                            key={provider.id}
                            data-provider-name={provider.provider}
                            value={provider.id}
                          >
                            {provider.provider}
                          </option>
                        ))}
                      </select>
                      {errors.networkProvider && (
                        <span className="text-red-500 text-sm">
                          {errors.networkProvider}
                        </span>
                      )}
                    </label>
                  </div>
                  {serviceSpinner && (
                    <div className="service-provider-spinner-wrap">
                      <FontAwesomeIcon icon={faSpinner} spin />
                    </div>
                  )}
                </div>

                <div className="flex">
                  <div className="mb-2  mr-2 w-1/2">
                    <label
                      className="block text-gray-500 text-xs"
                      htmlFor="product"
                    >
                      Product
                    </label>
                    <select
                      onChange={handleProductChange}
                      type="text"
                      id="product"
                      name="product"
                      value={formData.product}
                      className="w-full appearance-none border border-gray-300 text-gray-700 p-3 text-xs rounded-lg"
                    >
                      <option disabled value="">
                        select
                      </option>

                      {data.products.map((product) => (
                        <option key={product.id} value={product.id}>
                          {product.description}
                        </option>
                      ))}
                    </select>
                    {errors.product && (
                      <span className="text-red-500 text-sm">
                        {errors.product}
                      </span>
                    )}
                  </div>
                  <div className="mb-2 w-1/2">
                    <label
                      className="block text-gray-500 text-xs"
                      htmlFor="pricing"
                    >
                      Pricing Option
                    </label>
                    <select
                      type="text"
                      id="pricing"
                      name="pricing"
                      value={formData.pricing}
                      onChange={handlePricingChange}
                      className="w-full border appearance-none border-gray-300 text-gray-700 p-3 text-xs rounded-lg"
                    >
                      <option disabled selected value="">
                        select
                      </option>

                      {data.pricingOptions.map((option) => (
                        <option
                          key={option.price}
                          value={`${option.price}-${option.monthsPaidFor}`}
                        >
                          &#x20A6;
                          {formatNumber(option.price) +
                            " for " +
                            pluralMonths(option.monthsPaidFor)}
                        </option>
                      ))}
                    </select>
                    {errors.pricing && (
                      <span className="text-red-500 text-sm">
                        {errors.pricing}
                      </span>
                    )}
                  </div>
                </div>

                <div className="flex">
                  <div className="mb-2 w-1/2">
                    <label
                      className="block text-gray-500 text-xs"
                      htmlFor="smartcardNumber"
                    >
                      Smartcard Number
                    </label>
                    <input
                      type="text"
                      id="smartcardNumber"
                      name="smartcardNumber"
                      value={formData.smartcardNumber}
                      onChange={handleChange}
                      className="w-full border border-gray-300 text-gray-700 p-3 text-xs rounded-lg"
                    />
                    {errors.smartcardNumber && (
                      <span className="text-red-500 text-sm">
                        {errors.smartcardNumber}
                      </span>
                    )}
                  </div>

                  <div className="mb-2 w-1/2 ml-2">
                    <label
                      className="block text-gray-500 text-xs"
                      htmlFor="phoneNumber"
                    >
                      Customer Phone Number
                    </label>
                    <div className="">
                      <input
                        type="number"
                        id="phoneNumber"
                        name="phoneNumber"
                        placeholder="08000000000"
                        className="w-full border border-gray-300 p-3 text-gray-700 rounded-lg text-xs"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                      />
                    </div>
                    {errors.phoneNumber && (
                      <span className="text-red-500 text-sm">
                        {errors.phoneNumber}
                      </span>
                    )}
                  </div>
                </div>

                <div className="mb-4">
                  <label
                    className="block text-gray-500 text-xs"
                    htmlFor="email"
                  >
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="email address"
                    className="w-full border text-xs border-gray-300 text-gray-700 p-3 rounded-lg"
                  />
                  {errors.email && (
                    <span className="text-red-500 text-sm">{errors.email}</span>
                  )}
                </div>
              </section>
            )}

            {customerSpinner && <div className="loading"></div>}

            {formStep === 1 && (
              <section>
                <h2 className="font-bold text-center text-2xl mb-3">
                  Review Information
                </h2>
                <p className="text-black font-semibold text-sm text-center mt-2">
                  Review information and make payment
                </p>
                <div className="bg-white rounded mx-auto p-1 w-full h-full flex items-center align-items-center">
                  <div className="border bg-gray-100 mx-auto border-green-400 rounded-xl shadow-md px-12 mt-8 py-6">
                    {customerInformation ? (
                      <div className="text-center">
                        <p className="font-bold text-xs mt-2">NAME:</p>
                        <p className="text-xs">
                          {customerInformation.customerName}
                        </p>
                        <p className="font-bold text-xs mt-2">
                          SMARTCARD NUMBER:
                        </p>
                        <p className="text-xs">
                          {customerInformation.decoderNumber}
                        </p>
                        <p className="font-bold text-xs mt-2">
                          SERVICE PROVIDER:
                        </p>
                        <p className="text-xs">{formData.networkName}</p>
                        <p className="font-bold text-xs mt-2">BUNDLE:</p>
                        <p className="text-xs">{formData.productName}</p>
                        <p className="font-bold text-xs mt-2">
                          CUSTOMER NUMBER:
                        </p>
                        <p className="text-xs">{formData.phoneNumber}</p>
                        <p className="font-bold text-xs mt-2">AMOUNT:</p>
                        <p className="text-xs">
                          ₦ {formatNumber(splitAmount(formData.pricing))}
                        </p>
                        <p className="font-bold text-xs mt-2">EMAIL:</p>
                        <p className="text-xs">{formData.email}</p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </section>
            )}

            {renderButton()}
          </form>
        </div>
      </div>
    </>
  );
};

export default TvSubscription;
