import React from "react";
import posImg from "../assets/posImg.jfif";

const Reasons = () => {
  return (
    <div className="text-[#333333] px-8 py-16 mt-6">
      <div className="flex flex-col md:flex-row mx-auto justify-center">
          <div className="ml-8">
                <h1 className="md:text-3xl sm:text-2xl text-xl font-bold">
                  Interesting reasons to use BillerHQ for your Business.{" "}
                </h1>
                <h6 className="pt-2 flex items-center">
                  <svg
                    className="w-8 h-8 mr-2 text-green-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      d="M5.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4a1 1 0 00-1.414-1.414L7 8.586 5.707 7.293z"
                      clipRule="evenodd"
                      fillRule="evenodd"
                    />
                  </svg>
                  Instant transaction fulfilment
                </h6>
                <h6 className="pt-2 flex items-center">
                  <svg
                    className="w-8 h-8 mr-2 text-green-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      d="M5.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4a1 1 0 00-1.414-1.414L7 8.586 5.707 7.293z"
                      clipRule="evenodd"
                      fillRule="evenodd"
                    />
                  </svg>
                  One Account, multiple transaction point and users
                </h6>
                <h6 className="pt-2 flex items-center">
                  <svg
                    className="w-8 h-8 mr-2 text-green-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      d="M5.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4a1 1 0 00-1.414-1.414L7 8.586 5.707 7.293z"
                      clipRule="evenodd"
                      fillRule="evenodd"
                    />
                  </svg>
                  Earn Competitive commissions
                </h6>
                <h6 className="pt-2 flex items-center">
                  <svg
                    className="w-8 h-8 mr-2 text-green-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      d="M5.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4a1 1 0 00-1.414-1.414L7 8.586 5.707 7.293z"
                      clipRule="evenodd"
                      fillRule="evenodd"
                    />
                  </svg>
                  Access to POS and MPOS
                </h6>
                <h6 className="pt-2 flex items-center">
                  <svg
                    className="w-8 h-8 mr-2 text-green-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      d="M5.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4a1 1 0 00-1.414-1.414L7 8.586 5.707 7.293z"
                      clipRule="evenodd"
                      fillRule="evenodd"
                    />
                  </svg>
                  24/7 Customer Support
                </h6>
            </div>

          <div
            data-aos="fade-right"
            data-aos-delay="300"
            data-aos-duration="500"
            className="flex flex-col justify-center"
          >
        
                <img src={posImg} alt="mobile-app"  className="flex justify-center items-center w-[350px] h-[350px] mt-8 mx-auto border-none my-2 rounded-full" />
        
          </div>
      </div>
    </div>
  );
};

export default Reasons;
